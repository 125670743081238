<template>
  <div id="home">
    <h1>Animal</h1>
    <p>blabla</p>
    <router-link to="/models">Commencer</router-link>
  </div>
</template>

<script>

export default {
  name: 'Home',
  components: {
  }
}
</script>
