<template>
  <div id="models">
    <h1>Choisir un model</h1>
    <p>blabla</p>
    <div class="models-list">
      <model :model="model" v-for="model in models" :key="model.id"></model>
    </div>
  </div>
</template>
<style lang="scss">
#models {
  .models-list {
    display: flex;
    justify-content: space-between;
  }
}
</style>
<script>
import Model from '../components/Model'
export default {
  name: 'Home',
  components: {
    Model
  },
  data() {
    return {
      models: [
        {
          id: 0,
          name: 'chroma art',
          description: 'Description de chroma art',
          image: 'https://via.placeholder.com/300x600/ccc/fff?text=chroma%20art'
        },
        {
          id: 1,
          name: 'ink art',
          description: 'Description de ink art',
          image: 'https://via.placeholder.com/300x600/ccc/fff?text=ink art'
        },
        {
          id: 2,
          name: 'coffee stain art',
          description: 'Description de coffee stain art',
          image: 'https://via.placeholder.com/300x600/ccc/fff?text=coffee stain art'
        },
        {
          id: 3,
          name: 'newspaper art',
          description: 'Description de newspaper art',
          image: 'https://via.placeholder.com/300x600/ccc/fff?text=newspaper art'
        },
      ]
    }
  }
}
</script>
