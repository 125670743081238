import VueRouter from 'vue-router'
import Home from './pages/Home'
import Models from "./pages/Models";
import Order from "./pages/Order";
const routes = [
  { path: '/', component: Home },
  { path: '/models', component: Models},
  { path: '/order/:id', component: Order}
]
export default new VueRouter({
  routes
})
