<template>
  <div id="home">
    <h1>Commande de {{$route.params.id}}</h1>
    <p>blabla</p>

    <router-link :to="'/models'">Retour</router-link>

    <form @submit="handleSubmit">
      <div class="personal">
        <p>Info perso</p>
        <label>
          Prénom
          <input id="firstname" v-model="customer.firstname">
        </label>
        <label>
          Nom
          <input id="lastname" v-model="customer.lastname">
        </label>
        <label>
          Adresse
          <input id="address" v-model="customer.address">
        </label>
        <label>
          Code postal
          <input id="zip" v-model="customer.zip">
        </label>
        <label>
          Ville
          <input id="city" v-model="customer.city">
        </label>
        <label>
          Email
          <input id="email" v-model="customer.email">
        </label>
        <label>
          Gouts
          <textarea id="tastes" v-model="tastes.description"></textarea>
        </label>
        <label>
          Carte de payment
          <input id="cb" v-model="cb">
        </label>
        <label>
          Payer
          <button type="submit">Commander</button>
        </label>
      </div>
    </form>
  </div>
</template>

<script>

export default {
  name: 'Order',
  data() {
    return {
      customer: {
        firstname: '',
        lastname: '',
        address: '',
        zip: '',
        city: ''
      },
      tastes: {
        description: ''
      },
      cb: ''
    }
  },
  methods: {
    handleSubmit() {
      console.log('handle submit')
    }
  },
  components: {
  }
}
</script>
