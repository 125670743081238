<template>
  <div class="model">
    <router-link :to="`/order/${model.id}`">
      <img :src="model.image" :alt="model.name"/>
      <h2>{{model.name}}</h2>
      <p>{{model.description}}</p>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    model: {
      type: Object,
      default: () => { return {}}
    }
  }
}
</script>
